import * as React from "react";
import { hot } from "react-hot-loader/root";
import {
  ApiOutlined,
  BellOutlined,
  LineChartOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd5";
import { Link, Redirect, Route, Switch, useLocation } from "wouter";

import ChangePassword from "components/account_management/ChangePassword";
import DisplaySettings from "components/account_management/DisplaySettings";
import FeedSettings from "components/account_management/FeedSettings";
import { Integrations } from "components/account_management/Integrations";
import NotificationsPage from "components/account_management/notification_settings/NotificationsPage";
import Subscription from "components/account_management/Subscription";
import UserManagement from "components/account_management/UserManagement";
import { withAppLayout } from "components/app_layout/AppLayout";
import { IntegrationSetup } from "components/integrations/IntegrationSetup";
import { SubRoute } from "lib/core_components/SubRoute";
import { assertCurrentUser } from "lib/currentUser";
import { DialogManagerProvider } from "lib/providers/DialogManager";
import { SelfServeQuery } from "lib/StotlesApi";
import * as tracking from "lib/tracking";
import { TrackingProvider } from "lib/tracking";
import { CpvDimension } from "lib/types/models";
import { allowCrmIntegrationFlag } from "lib/utils/allowCrmIntegrationFlag";

import css from "./AccountManagementPage.module.scss";

const { Sider } = Layout;
const { SubMenu } = Menu;

// In the avatar navigation we want to just send the user to the generic /account-management
// The first tab in the list for now is account-settings/change-password but as we add more items this may change
// And in that case we'd modify the items below
const DEFAULT_ROUTE = "/account-management/subscription";

export enum Routes {
  FEED_SETTINGS = "/account-management/feed-settings",
  CHANGE_PASSWORD = "/account-management/change-password",
  SUBSCRIPTION = "/account-management/subscription",
  NOTIFICATIONS = "/account-management/notifications",
  MY_TEAM = "/account-management/my-team",
  DISPLAY_SETTINGS = "/account-management/display-settings",
  INTEGRATIONS_SETUP = "/account-management/integrations/setup",
  INTEGRATIONS = "/account-management/integrations",
}

type Props = {
  query?: { self_serve_query: SelfServeQuery };
  company_id: number;
  cpv_dimensions: CpvDimension[];
  use_supplier_name: boolean;
  feed_settings?: {
    present: boolean;
    id: number;
    use_supplier_name: string;
  };
};

function AccountManagementPage({ company_id, query, cpv_dimensions, use_supplier_name }: Props) {
  const [location, setLocation] = useLocation();
  const [activeKey, setActiveKey] = React.useState<string>("account");
  const [openKeys, setOpenKeys] = React.useState<string[]>([]);
  const user = assertCurrentUser();
  const company = user.company;
  const trackingData = { "Context source": "Compare all plans page" };

  React.useEffect(() => {
    switch (location) {
      case Routes.FEED_SETTINGS:
        setActiveKey("feed-settings");
        tracking.pageView("Account Management - Feed Settings", {});
        break;
      case Routes.CHANGE_PASSWORD:
        setActiveKey("change-password");
        setOpenKeys((oldOpenTabs) => oldOpenTabs.concat("account-settings"));
        tracking.pageView("Account Management - Change Password", {});
        break;
      case Routes.DISPLAY_SETTINGS:
        setActiveKey("display-settings");
        setOpenKeys((oldOpenTabs) => oldOpenTabs.concat("account-settings"));
        tracking.pageView("Account Management - Display Settings", {});
        break;
      case Routes.SUBSCRIPTION:
        setActiveKey("subscription");
        setOpenKeys((oldOpenTabs) => oldOpenTabs.concat("account-settings"));
        tracking.pageView("Account Management - Subscription", {});
        break;
      case Routes.MY_TEAM:
        setActiveKey("my-team");
        tracking.pageView("Account Management - My team", {});
        break;
      case Routes.INTEGRATIONS:
        setActiveKey("integrations");
        tracking.pageView("Account Management - Integrations", {});
        break;
    }
  }, [location, setLocation]);

  return (
    <Layout>
      <Sider width={250}>
        <Menu
          mode="inline"
          selectedKeys={activeKey ? [activeKey] : undefined}
          openKeys={openKeys}
          onOpenChange={(openKeys: string[]) => setOpenKeys(openKeys)}
          style={{ height: "100%" }}
        >
          <SubMenu
            key={"account-settings"}
            icon={<UserOutlined />}
            title={<span>Account settings</span>}
          >
            <Menu.Item key="subscription">
              <Link to={Routes.SUBSCRIPTION}>Subscription</Link>
            </Menu.Item>

            <Menu.Item key="display-settings">
              <Link to={Routes.DISPLAY_SETTINGS}>Display settings</Link>
            </Menu.Item>
            <Menu.Item key="change-password">
              <Link to={Routes.CHANGE_PASSWORD}>Change password</Link>
            </Menu.Item>
          </SubMenu>
          <Menu.Item key="feed-settings" icon={<LineChartOutlined />}>
            <Link to={Routes.FEED_SETTINGS}>Signal settings</Link>
          </Menu.Item>
          <Menu.Item key="notifications" icon={<BellOutlined />}>
            <Link to={Routes.NOTIFICATIONS}>Notifications</Link>
          </Menu.Item>
          <Menu.Item key="my-team" icon={<TeamOutlined />}>
            <Link to={Routes.MY_TEAM}>My team</Link>
          </Menu.Item>
          {allowCrmIntegrationFlag() && (
            <Menu.Item key="integrations" icon={<ApiOutlined />}>
              <Link to={Routes.INTEGRATIONS}>Integrations</Link>
            </Menu.Item>
          )}
        </Menu>
      </Sider>
      <div className={activeKey === "integrations" ? css.whiteBackground : css.pageBackground}>
        <DialogManagerProvider>
          <Switch>
            <Route path={Routes.FEED_SETTINGS}>
              <TrackingProvider data={{ "Page source": "Feed settings Page" }}>
                <FeedSettings
                  company_id={company_id}
                  query={query}
                  cpvDimensions={cpv_dimensions}
                  useSupplierName={use_supplier_name}
                  internationalDataAccess={company.has_international_data_access || false}
                />
              </TrackingProvider>
            </Route>
            <Route path={Routes.DISPLAY_SETTINGS} component={DisplaySettings} />
            <Route path={Routes.CHANGE_PASSWORD} component={ChangePassword} />
            <Route path={Routes.SUBSCRIPTION}>
              <Subscription trackingData={trackingData} />
            </Route>
            <SubRoute
              path={"/account-management/notifications/:rest*"}
              component={NotificationsPage}
              onMatch={() => setActiveKey("notifications")}
            />
            <Route path={Routes.MY_TEAM} component={UserManagement} />
            <Route path={Routes.INTEGRATIONS_SETUP} component={IntegrationSetup} />
            {allowCrmIntegrationFlag() ? (
              <Route path={Routes.INTEGRATIONS} component={Integrations} />
            ) : (
              <></>
            )}
            <Route>
              <Redirect href={DEFAULT_ROUTE} />
            </Route>
          </Switch>
        </DialogManagerProvider>
      </div>
    </Layout>
  );
}

function AdminBarComp({ feed_settings }: Props) {
  if (!feed_settings?.id) {
    return null;
  }

  return (
    <>
      <a href={`/admin/company/feed_settings/${feed_settings.id}`} target="_blank">
        Go to Feed Settings AP
      </a>
      <span>Supplier name search: {feed_settings.use_supplier_name ? "enabled" : "disabled"}</span>
    </>
  );
}

export default hot(withAppLayout(AccountManagementPage, { AdminBarComp }));
