import React, { useEffect, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { Divider, InputNumber, Tooltip } from "antd5";

import { SelectBuyerList } from "components/form_components/BuyerListSelect";
import { BuyerSelect } from "components/form_components/BuyerSelect";
import { BuyerTypeSelect } from "components/form_components/BuyerTypeSelect";
import { CountryRegionSelect } from "components/form_components/CountryRegionSelect";
import CpvCodeSelect from "components/form_components/CpvCodeSelect";
import { FrameworkSelect } from "components/form_components/FrameworkSelect";
import { Checkbox, DateFilter, DateRange, Input, Select } from "components/form_components/Inputs";
import { KeywordSelect } from "components/form_components/keyword_select/KeywordSelect";
import { MentionsAwardsFilter } from "components/form_components/MentionsAwardsFilter";
import { NewRelevanceScoreButtons } from "components/form_components/NewRelevanceScoreButtons";
import { NewSupplierSelect } from "components/form_components/NewSupplierSelect";
import { NoticeListSelect } from "components/form_components/NoticeListSelect";
import RecordStageButtons from "components/form_components/RecordStageButtons";
import { RelevanceScoreButtons } from "components/form_components/RelevanceScoreButtons";
import { SignalSelect } from "components/form_components/SignalSelect";
import { UserSelect } from "components/form_components/UserSelect";
import { SelectQualificationForm } from "components/record_qualifications/SelectQualification";
import { FrameworkFilterOptions } from "components/record_search/types";
import RedactedWrapper from "lib/core_components/RedactedWrapper";
import { cpvDimensionSelectOptions } from "lib/data/optionItems";
import FeatureToggles, { Feature } from "lib/FeatureToggles";
import { useSignalSettingsGQL } from "lib/hooks/api/teams/useSignalSettingsGQL";
import { useSubscription } from "lib/providers/Subscription";
import { useTracking } from "lib/tracking";
import { TenderStage } from "lib/types/models";
import {
  CPV_CODES_FILTER,
  FILTER_COLLAPSE_NOTICES,
  KEYWORDS_ON_THE_FLY,
  LAYER_CAKE_FILTERS,
  NEW_NOTICES_SEARCH,
  NEW_SUPPLIER_FILTER,
  NOTICE_FREE_TEXT_SEARCH,
  useVariableValue,
} from "../../lib/featureFlags";
import { parseBooleanValue, useURLState } from "../../lib/hooks/useURLState";
import { camelToSentence, isObjEmpty } from "../../lib/utils";
import FilterCollapse from "../filter_form/filter_collapse/FilterCollapse";
import FilterCollapseHeader from "../filter_form/filter_collapse_header/FilterCollapseHeader";
import FilterFormTitle from "../filter_form/filter_form_title/FilterFormTitle";
import { countFilters } from "../filter_form/utils";
import { Radio } from "../form_components/Inputs";
import { SupplierSelect } from "../form_components/SupplierSelect";
import { createFeedFilterChangedEvents } from "../my_feed/tracking";
import { EMPTY_FILTERS } from "../my_feed/useMyFeedPageState";
import { noticeFilterContents } from "./filter_groups";
import { NoticeFilters } from "./utils";

import css from "./FilterForm.module.scss";

export const groupedKeys: Record<FilterGroupKeys, (keyof NoticeFilters)[]> = {
  keywords: ["keywords", "excludeKeywords"],
  signals: ["signals"],
  procurementStage: ["stage"],
  buyers: ["buyers", "buyerListIds", "buyerCategories", "buyerCountryRegions"],
  suppliers: ["suppliers", "supplierSme", "supplierGuids", "supplierMentionType"],
  contractValue: ["value"],
  contractDates: ["closeDate", "expiryDate"],
  signalScore: ["relevanceScore"],
  frameworks: ["frameworkIds", "frameworkFilterOptions"],
  cpvCode: ["cpvDimensions", "cpvCodes"],
  workflow: ["assignee", "procurementStageQualifications", "noticeLists"],
};

type FilterGroupKeys = keyof typeof noticeFilterContents;

export type FilterFormLocation = "my_feed" | "buyers" | "suppliers" | "call-offs";

export type FilterOptions = {
  disabledFields?: keyof NoticeFilters;
  disabledSections?: FilterGroupKeys[];
  hiddenFilters?: (keyof NoticeFilters)[];
};

type FilterFormProps = {
  onChange: (filters: NoticeFilters) => void;
  filters: NoticeFilters;
  options?: FilterOptions;
  showTitle?: boolean;
  onClose: () => void;
  activeGroups: string[];
  onActiveGroupsChange: (value: string[]) => void;
  location?: FilterFormLocation;
};

export function FilterForm({
  onChange,
  filters,
  options,
  showTitle = false, // remove once feature flag is removed
  onClose,
  location,
  activeGroups,
  onActiveGroupsChange,
}: FilterFormProps) {
  const { logEvents } = useTracking();
  const previous = useRef(filters);
  const isFreeTextSearchEnabled = useVariableValue(NOTICE_FREE_TEXT_SEARCH, false);
  const keywordsOnTheFlyFilter = useVariableValue(KEYWORDS_ON_THE_FLY, false);
  const cpvCodesFilter = useVariableValue(CPV_CODES_FILTER, false);
  const isLayerCakeFiltersEnabled = useVariableValue(LAYER_CAKE_FILTERS, false);
  const isCollapseEnabled = useVariableValue(FILTER_COLLAPSE_NOTICES, false);

  const {
    control,
    watch,
    formState: { touchedFields },
    handleSubmit,
    setValue,
    reset,
  } = useForm<NoticeFilters>({
    mode: "onChange",
    defaultValues: filters,
  });

  const data = watch();

  const subscription = useSubscription();

  const [, setShowNoView] = useURLState<boolean | undefined>(
    "showNoView",
    false,
    parseBooleanValue,
  );

  const displayFrameworksSection =
    !options?.disabledSections?.includes("frameworks") &&
    subscription.hasDataTypes("FRAMEWORKS") &&
    FeatureToggles.isEnabled(Feature.FRAMEWORK_DETAILS);

  const enableNewSupplierFilter =
    useVariableValue(NEW_SUPPLIER_FILTER, false) && window.currentUser?.use_supplier_name === false;

  const { data: signalSettings } = useSignalSettingsGQL();

  // submit form onChange
  useEffect(() => {
    const subscription = watch(() =>
      handleSubmit((d) => {
        logEvents(...createFeedFilterChangedEvents(previous.current, d, signalSettings));
        previous.current = d;
        onChange(d);
      })(),
    );
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch, logEvents, onChange, filters, signalSettings]);

  function hideNullsWhenSet(fieldName: "closeDate" | "expiryDate", value: DateFilter) {
    // don't set when clearing a field
    const hasTransitionedToTruthy = Object.keys(value).some((field) => {
      const prevFieldValue = previous.current?.[fieldName]?.filter;
      return isObjEmpty(prevFieldValue) && value[field];
    });

    // don't set if checkbox has been changed manually already
    if (hasTransitionedToTruthy && !touchedFields[fieldName]?.hideNulls) {
      setValue(`${fieldName}.hideNulls`, true);
    }
  }

  const counts = countFilters(filters, groupedKeys);

  if (keywordsOnTheFlyFilter) {
    counts.signals = filters.signals.length || 0;
  }

  function clearFilter(key: FilterGroupKeys) {
    const keys = groupedKeys[key];
    keys.forEach((k) => {
      setValue(k, EMPTY_FILTERS[k]);
    });
  }

  const filterProps = {
    control,
    data,
    options,
    hideNullsWhenSet,
  };

  function getFilterGroups() {
    let items = (Object.keys(groupedKeys) as FilterGroupKeys[]).map((key) => {
      const ContentComponent = noticeFilterContents[key];

      // Hide for now as we test with users
      // TODO: enable or remove once testing complete
      // const tag = (filterName: string) => {
      //   if (
      //     isLayerCakeFiltersEnabled &&
      //     ["Keywords", "Suppliers", "CPV code"].includes(filterName)
      //   ) {
      //     return <NewIcon className={css.newIcon} caps={true} />;
      //   }
      // };
      return {
        key,
        header: (
          <FilterCollapseHeader
            title={key === "cpvCode" ? "CPV code" : camelToSentence(key)}
            count={counts[key]}
            clear={() => clearFilter(key)}
            // tag={tag}
          />
        ),
        content: <ContentComponent {...filterProps} />,
      };
    });

    if (!keywordsOnTheFlyFilter) items = items.filter((item) => item.key !== "keywords");
    if (!displayFrameworksSection) items = items.filter((item) => item.key !== "frameworks");

    const disabledSections = options?.disabledSections || [];
    items = items.filter((item) => !disabledSections.includes(item.key));

    if (options?.hiddenFilters?.includes("suppliers"))
      items = items.filter((item) => item.key !== "suppliers");

    isLayerCakeFiltersEnabled
      ? (items = items.filter((item) => item.key !== "signals"))
      : (items = items.filter((item) => item.key !== "signalScore"));

    return items;
  }

  const items = getFilterGroups();

  function clearAll() {
    reset({ ...EMPTY_FILTERS, text: filters.text || "" });
    setTimeout(() => setShowNoView(true), 320); // ensuring happens after reset, otherwise looks odd as happens in two stages
  }

  const useNewNoticesSearch = useVariableValue(NEW_NOTICES_SEARCH, false);

  return (
    <form className={css.form} aria-label="Notice filters">
      {showTitle && (
        <FilterFormTitle
          clearFilters={clearAll}
          showClear={Object.values(counts).some(Boolean)}
          onClose={onClose}
        />
      )}
      {isCollapseEnabled && (
        <>
          {location === "suppliers" && enableNewSupplierFilter && (
            <div className={css.mentionsAwardsWrapper}>
              <MentionsAwardsFilter control={control} isSingleSupplier />
            </div>
          )}
          <FilterCollapse items={items} value={activeGroups} onChange={onActiveGroupsChange} />
        </>
      )}
      {!isCollapseEnabled && (
        <>
          <h3 className={css.sectionTitle}>Your information</h3>
          <div className={css.oldFilterSection} aria-label="User specific filters">
            {keywordsOnTheFlyFilter && (
              <KeywordSelect name="keywords" label="Include keywords" control={control} />
            )}
            {options?.hiddenFilters?.includes("signals") ? null : (
              <>
                <SignalSelect
                  name="signals"
                  label="Signals"
                  control={control}
                  placeholder="Select..."
                  allowClear
                />
                <Controller
                  name="relevanceScore"
                  control={control}
                  render={({ field }) => (
                    <div className={css.inputContainer}>
                      <label htmlFor="relevanceScore" className={css.inputLabel}>
                        <h3>Signal score</h3>
                      </label>
                      {useNewNoticesSearch ? (
                        <NewRelevanceScoreButtons {...field} entityType="notices" />
                      ) : (
                        <RelevanceScoreButtons {...field} checkedRelevanceScores={field.value} />
                      )}
                    </div>
                  )}
                />
              </>
            )}
            <UserSelect
              name="assignee"
              label="Assignee"
              control={control}
              mode="multiple"
              placeholder="Select..."
              allowClear
            />
            <SelectQualificationForm
              label="Qualification"
              name="procurementStageQualifications"
              control={control}
              allowClear
            />
            <NoticeListSelect
              name="noticeLists"
              label="Notice lists"
              control={control}
              mode="multiple"
              allowClear
            />
          </div>
          <Divider />
          {options?.disabledSections?.includes("buyers") ? null : (
            <>
              <h3 className={css.sectionTitle}>Buyer information</h3>
              <div className={css.oldFilterSection} aria-label="Buyer filters">
                <SelectBuyerList
                  name="buyerListIds"
                  label="Buyer lists"
                  control={control}
                  placeholder="Select..."
                  mode="multiple"
                  disabled={
                    data.buyers.length > 0 || options?.disabledFields?.includes("buyerListIds")
                  }
                  allowClear
                />
                <BuyerSelect
                  name="buyers"
                  label="Buyers"
                  control={control}
                  mode="multiple"
                  placeholder="Search buyers..."
                  disabled={
                    data.buyerListIds.length > 0 || options?.disabledFields?.includes("buyers")
                  }
                  allowClear
                />
                <BuyerTypeSelect
                  name="buyerCategories"
                  control={control}
                  multiple
                  label="Buyer type"
                  placeholder="Select..."
                  disabled={options?.disabledFields?.includes("buyerCategories")}
                  allowClear
                />
                <CountryRegionSelect
                  name="buyerCountryRegions"
                  control={control}
                  label="Buyer location"
                  placeholder="Select..."
                  disabled={options?.disabledFields?.includes("buyerCountryRegions")}
                  allowClear
                />
              </div>
              <Divider />
            </>
          )}
          {!options?.disabledSections?.includes("suppliers") && (
            <>
              <h3 className={css.sectionTitle}>Supplier information</h3>
              <div className={css.oldFilterSection} aria-label="Supplier filters">
                {enableNewSupplierFilter ? (
                  <>
                    {!options?.hiddenFilters?.includes("supplierGuids") && (
                      <NewSupplierSelect
                        name="supplierGuids"
                        label="Supplier Guids"
                        control={control}
                      />
                    )}

                    {!options?.hiddenFilters?.includes("supplierMentionType") && (
                      <RedactedWrapper
                        redactContent={<MentionsAwardsFilter control={control} />}
                        requiredDataType="SUPPLIERS"
                        contextSource="Supplier filter"
                      >
                        {data.supplierGuids && data.supplierGuids.length > 0 && (
                          <>
                            <MentionsAwardsFilter control={control} />
                            <Divider style={{ margin: "0px 8px" }} />
                          </>
                        )}
                      </RedactedWrapper>
                    )}
                  </>
                ) : (
                  <SupplierSelect
                    name="suppliers"
                    label="Suppliers"
                    control={control}
                    mode="multiple"
                    placeholder="Search suppliers..."
                    allowClear
                  />
                )}
                {!options?.hiddenFilters?.includes("supplierSme") && (
                  <RedactedWrapper
                    redactContent={
                      <Radio
                        label="SME Supplier?"
                        name={"supplierSme"}
                        control={control}
                        disabled
                        options={[
                          { label: "Show all suppliers", value: "" },
                          { label: "Show only SMEs", value: true },
                          { label: "Hide SMEs", value: false },
                        ]}
                        optionType="default"
                        direction="vertical"
                      />
                    }
                    requiredDataType="SUPPLIERS"
                    contextSource="Supplier filter"
                  >
                    <Radio
                      label="SME Supplier?"
                      name={"supplierSme"}
                      control={control}
                      options={[
                        { label: "Show all suppliers", value: "" },
                        { label: "Show only SMEs", value: true },
                        { label: "Hide SMEs", value: false },
                      ]}
                      optionType="default"
                      direction="vertical"
                    />
                  </RedactedWrapper>
                )}
              </div>
              <Divider />
            </>
          )}
          <h3 className={css.sectionTitle}>Contract information</h3>
          <div className={css.oldFilterSection} aria-label="Contract info filters">
            {!isFreeTextSearchEnabled && <Input name="text" label="Keywords" control={control} />}
            <div className={css.inputContainer}>
              <label htmlFor="value" className={css.inputLabel}>
                <h3>Value</h3>
              </label>
              <div className={css.valueFields}>
                <Controller
                  name="value.from"
                  control={control}
                  rules={{ min: 0 }}
                  render={({ field, fieldState }) => (
                    <InputNumber
                      {...field}
                      status={fieldState.error && "error"}
                      min={0}
                      placeholder="From"
                    />
                  )}
                />
                -
                <Controller
                  name="value.to"
                  control={control}
                  rules={{ min: data.value.from || 0 }}
                  render={({ field, fieldState }) => (
                    <InputNumber
                      {...field}
                      status={fieldState.error && "error"}
                      min={0}
                      placeholder="To"
                    />
                  )}
                />
              </div>
              <Checkbox
                label=""
                name="value.hideNulls"
                control={control}
                fieldLabel="Hide notices that don't have a value"
              />
            </div>
            {cpvCodesFilter ? (
              <CpvCodeSelect name="cpvCodes" label="CPV Codes" control={control} />
            ) : (
              <Select
                name="cpvDimensions"
                label="Sector"
                control={control}
                options={cpvDimensionSelectOptions}
                optionFilterProp="label"
                mode="multiple"
                placeholder="Select"
                allowClear
              />
            )}
            <Controller
              name="stage"
              control={control}
              render={({ field }) => (
                <div className={css.inputContainer}>
                  <label htmlFor="value" className={css.inputLabel}>
                    <h3>Stage</h3>
                  </label>
                  <RecordStageButtons
                    {...field}
                    checkedStages={field.value}
                    availableStages={[
                      TenderStage.STALE_PRE_TENDER,
                      TenderStage.PRE_TENDER,
                      TenderStage.OPEN,
                      TenderStage.CLOSED,
                      TenderStage.AWARDED,
                    ]}
                  />
                </div>
              )}
            />
          </div>
          <Divider />
          <h3 className={css.sectionTitle}>Dates</h3>
          <div className={css.oldFilterSection} aria-label="Date filters">
            <div>
              <DateRange
                name="closeDate.filter"
                control={control}
                label="Close date"
                onChange={(value) => hideNullsWhenSet("closeDate", value)}
              />
              <Checkbox
                label=""
                name="closeDate.hideNulls"
                control={control}
                fieldLabel="Hide notices without close dates"
              />
            </div>
            <div>
              <DateRange
                name="expiryDate.filter"
                control={control}
                label="Expiry date"
                onChange={(value) => hideNullsWhenSet("expiryDate", value)}
              />
              <Checkbox
                label=""
                name="expiryDate.hideNulls"
                control={control}
                fieldLabel="Hide notices without expiry dates"
              />
            </div>
            <DateRange name="publishDate" control={control} label="Date published" />
          </div>
          {displayFrameworksSection && (
            <>
              <Divider />
              <h3 className={css.sectionTitle}>Frameworks</h3>
              <div className={css.oldFilterSection} aria-label="Framework filters">
                <RedactedWrapper
                  redactContent={
                    <Radio
                      label=""
                      name={"frameworkFilterOptions"}
                      control={control}
                      disabled
                      options={[
                        { label: "Include all framework activity", value: "" },
                        {
                          label: (
                            <Tooltip
                              placement="left"
                              title="Show only call-offs that relate to frameworks agreements"
                              overlayClassName={css.tooltip}
                            >
                              Show only call-offs
                            </Tooltip>
                          ),
                          value: FrameworkFilterOptions.SHOW_ONLY_FRAMEWORK_CALL_OFFS,
                        },
                        {
                          label: (
                            <Tooltip
                              placement="left"
                              title="Any pre-tenders, tenders or contract awards that relate to the creation of a framework will be hidden. Call-offs within frameworks will still appear."
                              overlayClassName={css.tooltip}
                            >
                              Hide framework agreement notices
                            </Tooltip>
                          ),
                          value: FrameworkFilterOptions.HIDE_FRAMEWORK_AGREEMENT_NOTICES,
                        },
                        {
                          label: (
                            <Tooltip
                              placement="left"
                              title="Any notices relating to framework agreements and their underlying call-offs will be hidden."
                              overlayClassName={css.tooltip}
                            >
                              Hide all framework activity
                            </Tooltip>
                          ),
                          value: FrameworkFilterOptions.HIDE_ALL_FRAMEWORK_ACTIVITY,
                        },
                      ]}
                      optionType="default"
                      direction="vertical"
                    />
                  }
                  // TODO: Add framework paywall here
                  //   requiredDataType="SUPPLIERS"
                  contextSource="Framework activity type filter"
                >
                  <Radio
                    label=""
                    name={"frameworkFilterOptions"}
                    control={control}
                    options={[
                      { label: "Include all framework activity", value: "" },
                      {
                        label: (
                          <Tooltip
                            placement="left"
                            title="Show only call-offs that relate to frameworks agreements"
                            overlayClassName={css.tooltip}
                          >
                            Show only call-offs
                          </Tooltip>
                        ),
                        value: FrameworkFilterOptions.SHOW_ONLY_FRAMEWORK_CALL_OFFS,
                      },
                      {
                        label: (
                          <Tooltip
                            placement="left"
                            title="Any pre-tenders, tenders or contract awards that relate to the creation of a framework will be hidden. Call-offs within frameworks will still appear."
                            overlayClassName={css.tooltip}
                          >
                            Hide framework agreement notices
                          </Tooltip>
                        ),
                        value: FrameworkFilterOptions.HIDE_FRAMEWORK_AGREEMENT_NOTICES,
                      },
                      {
                        label: (
                          <Tooltip
                            placement="left"
                            title="Any notices relating to framework agreements and their underlying call-offs will be hidden."
                            overlayClassName={css.tooltip}
                          >
                            Hide all framework activity
                          </Tooltip>
                        ),
                        value: FrameworkFilterOptions.HIDE_ALL_FRAMEWORK_ACTIVITY,
                      },
                    ]}
                    optionType="default"
                    direction="vertical"
                  />
                </RedactedWrapper>
                <FrameworkSelect
                  name="frameworkIds"
                  label="Frameworks"
                  control={control}
                  mode="multiple"
                  placeholder="Search frameworks..."
                  allowClear
                />
              </div>
              <Divider />
            </>
          )}
        </>
      )}
    </form>
  );
}
