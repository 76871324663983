import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

import { KeywordSelect } from "components/form_components/keyword_select/KeywordSelect";
import { useDocumentTracking } from "./tracking";

import css from "./FilterForm.module.scss";

export type DocumentViewerFilters = {
  keywords: string[];
};

type FilterFormProps = {
  filters: DocumentViewerFilters;
  onChange: (filters: DocumentViewerFilters) => void;
};

export default function FilterForm({ filters, onChange }: FilterFormProps) {
  const tracking = useDocumentTracking();

  const { control, watch, handleSubmit } = useForm<DocumentViewerFilters>({
    mode: "onChange",
    defaultValues: filters,
  });

  useEffect(() => {
    const subscription = watch(() =>
      handleSubmit((d) => {
        onChange(d);
        tracking.documentSearched();
      })(),
    );
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch, onChange, tracking]);

  return (
    <form>
      <KeywordSelect
        name="keywords"
        label="Keywords"
        control={control}
        containerClassName={css.keywordFilter}
      />
    </form>
  );
}
